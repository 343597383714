/* .container-card {
  position: relative;
  display: flex;
  height: 888px; 
} */

/* .card-container {
    position: relative;
    left: 10%;
    top: 0;
    width: 500px; /* Set the desired width of the tree container 
    z-index: 2;
  } */

@media screen and (orientation: portrait) {
  .p-dialog-enter-done {
    width: auto !important;
    zoom: 99% !important;
    -moz-transform: scale(99%);
  }
}

@media screen and (orientation: landscape) {

  /* .player-wrapper {
        object-fit: contain !important;
    } */
  .p-dialog-enter-done {
    width: auto !important;
    /* zoom: 43% !important; */
  }
}

@media screen and (min-width: 1001px) {
  body {
    .layout-topbar {
      display: none !important;
    }

    .card-container {
      position: relative !important;
      left: 30% !important;
      top: 0 !important;
      width: auto !important;
      z-index: 2 !important;
      object-fit: cover !important;
    }

  }
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
  .text-image {
    width: calc(80vh + 80vh * .3) !important;
    height: 80vh !important;
  }
}

@media only screen and (max-width: 1000px) {
  body {
    .slide-menu-button {
      display: block !important;


      .p-dialog {
        /* max-width: 95% !important; */
        background-color: aliceblue !important;
      }

      .p-dialog-content {
        display: contents;
        border: .5%;
      }
    }

    /* img {
        width: 200px !important;
        height: 300px !important;
        object-fit: cover !important;
      } */
  }

}