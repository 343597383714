.container {
    position: relative !important;
    height: 888px; /* Set the desired height of your container */
  }
  
  .background-image {
    position: absolute;
    /* background-size: cover; */
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
    /* object-fit: cover; */
  }
  
  .button-container-ski {
    position: absolute !important;
    top: 43% !important;
    left: 20% !important;
    transform: translate(-50%, -50%) !important;
  }
  
  .overlay-button-ski {
    display: block !important;
    margin-bottom: 2px !important;
    padding: 10px 20px !important;
    background-color: #fff !important;
    color: #000 !important;
    font-size: 16px !important;
    width: 190PX !important;
    border: none !important;
    border-radius: 15px !important;
  }

  .button-container-snowboard {
    position: absolute !important;
    top: 43% !important;
    left: 63% !important;
    transform: translate(-50%, -50%) !important;
  }
  
  .overlay-button-snowboard {
    display: block !important;
    margin-bottom: 2px !important;
    padding: 10px 20px !important;
    background-color: #fff !important;
    color: #000 !important;
    font-size: 16px !important;
    width: 190PX !important;
    border: none !important;
    border-radius: 15px !important;
  }
  
  .button-container-footer {
    position: absolute;
    top: 72%;
    left: 49%;
    transform: translateX(-50%, -50%);
  }
  
  .overlay-button-footer {
    display: block;
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    width: 190PX;
    border: none;
    border-radius: 15px;
  }

  .overlay-button-languge-selector{
    font: small;
    background-color: transparent !important;
    color: #000 !important;
    order: none !important;
    border-radius: 12px !important;
  }

  .label-language-selector{
    position: absolute !important;
    display: inline-flex !important;
    background-color: #fff !important;
    top: 5% !important;
    right: calc(12% + 100px)  !important;
    transform: translate(-50%, -50%) !important;
  }

  .overlay-button-label-signs{
    font: small;
    background-color:transparent !important;
    color: #000 !important;
    order: none !important;
    border-radius: 12px !important;
  }

  .label-signs{
    position: absolute !important;
    background-color: #fff !important;
    top: 5% !important;
    right: 10% !important;
    transform: translate(-50%, -50%) !important;
  }
/* 
  .slide-menu-button{
    display: none;
  } */