.p-galleria-footer{
    display: none;
}

.p-galleria-thumbnail-wrapper{
    display: none !important;
}

.p-galleria-caption{
    display: none;
}

@media screen and (min-width: 1001px){
    body {
        .image-galeria{
            width: 80%;
            height: auto;
        }
    }
}

@media only screen and (max-width: 1000px) {
    body {
        .image-galeria{
            width: 100%;
            height: auto;
        }
    }
}