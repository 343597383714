@media only screen and (min-width: 1001px){
    /* .login-progress-spinner{
        width: "50px";
        height: "50px";
        position: "fixed";
        left: "50%";
        top: "33%";
        padding: "30px";
    } */
}

@media only screen and (max-width: 1000px) {
    .login-body{
        /* display: none; */
        height:min-content !important;
    }

    .login-panel{
        /* display: none !important; */
        height: fit-content !important;
    }
}