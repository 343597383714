/* @media only screen and (max-width: 1000px) {
  body {
  .mobile-burger-menu {
    display: none;
  }
}
} */
/* 
.navbar .right-side {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 24px;
   -ms-grid-columns: (auto)[2];
      grid-template-columns: repeat(2, auto);
}

.navbar .right-side .buttons button {
  cursor: pointer;
  border: none;
  border-radius: 30px;
  padding: 10px 23px;
  background: -webkit-gradient(linear, left top, right top, from(#1689ed), to(#ba1aba));
  background: linear-gradient(90deg, #1689ed, #ba1aba);
  color: white;
  font-size: 14px;
  font-weight: thin;
}

.navbar .right-side .buttons button:hover {
  background: blue;
}

.navbar .right-side .burger-menu-icon {
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.navbar .right-side .languageSelect {
  display: block;
}

.navbar .right-side .burger-menu {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: #444;
  height: 100vh;
  z-index: 10;
  width: var(--burger-menu-width);
  padding: 20px;
  -webkit-transform: translateX(var(--burger-menu-width));
          transform: translateX(var(--burger-menu-width));
  -webkit-transition: all 1300ms;
  transition: all 1300ms;
}

.navbar .right-side .burger-menu i {
  font-size: 20px;
  position: relative;
  color: white;
  margin-left: auto;
  cursor: pointer;
}

.navbar .right-side .burger-menu .burger-links {
  padding: 0px;
  margin-left: auto;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
}

.navbar .right-side .burger-menu .burger-links li {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.navbar .right-side .burger-menu .burger-links li i {
  cursor: pointer;
}

.navbar .right-side .burger-menu .burger-links li a {
  color: white;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
  font-size: 18px;
}

.navbar .right-side .burger-menu .burger-links li a:hover {
  color: #e3e3e3;
}

.navbar .right-side .burger-menu .language-select {
  margin-left: auto;
}

.navbar-open .right-side .burger-menu {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.navbar-dark {
  background-color: #293241;
} */

@media only screen and (max-width: 1000px) {
  /* .navbar .right-side .languageSelect {
    display: none;
  }
  .navbar .right-side .burger-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar .right-side .burger-menu-icon {
    display: block;
  }
  .navbar .left-side .links {
    display: none;
  } */

  .slide-menu{
    margin-top: 12px !important;
    background-color: transparent !important;
    width: calc(67vw) !important;
    display: inline !important;
  }

  .slide-menu-button{
  position: fixed !important;
  top: .3% !important;
  left: 1% !important;
  /* width: 100% ; */
  /* background-color: #a39a9a;
  color: #fff;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
  }

  .p-slidemenu-wrapper{
    display: block !important;
    background-color: transparent !important;
    /* width: 200% !important; */
    width: calc(67vw) !important;
  }

  .p-slidemenu-content {
    position: absolute !important;
    top: 7% !important;
    left: 0 !important;
    width: calc(67vw) !important;
    background-color: #f1f6fb !important;

    /* font-size: 30px; */
    color: #fff !important;
    /* padding: 10px !important; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
  }

  .p-slidemenu-backward{
    display: none;
  }
  .p-slidemenu-rootlist{
    list-style: none;
    padding: 10px;
    margin: 0;
    margin-left: 10px;
  }

  .p-menuitem li {
    list-style: none;
    margin-top: 12px;
    margin-right: 20px;
  }

  .p-menuitem-link{
    list-style: none;
    width: calc(60vw) !important;
  }

  .p-menuitem-text{
    
    font-size: 14px !important;
    /* color: #151313 !important;
    margin-top: 12px !important;
    margin-right: 20px !important; */
    text-decoration: none !important;
    /* padding: 5px 10px !important; */
  }
  
  li{
    margin-top: 17px;
  }
  /* .p-menuitem li:last-child {
    margin-right: 0;
  }
  
  .p-menuitem li a {
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
  }
  
  .menu-list li a:hover {
    background-color: #555;
  } */
  
  /* Style for the main content */
  .main-content {
    padding-top: 60px; /* Add padding to avoid overlapping with the fixed menu */
    margin: 20px;
  }  
  
  /* .p-slidemenu-wrapper{
    position: absolute;
    top: 7%;
    left: 0;
    width: 33%;
    background-color: transparent;
    color: #fff;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  } 

   .card-container:hover{
    display: none;
  }*/
/*
  .card-container.show{
    display: block;
  } */
}

.slide-menu-overlay-button-languge-selector{
  /* display: inline-flexbox !important; */
  font-size: 14px !important;
  
  font: small !important;
  background-color: aliceblue !important;
  color: #3687c1 !important;
}

.slide-menu-button-language-selector{
  display: inline-flex !important;
  position: fixed !important;
  top: .3% !important;
  right: calc(12% + 100px) !important;
}

.slide-menu-overlay-button-label-signs{
  /* display: inline-block !important; */
  font-size: 14px !important;
  
  font: small !important;
  background-color: aliceblue !important;
  color: #3687c1 !important;
}

.slide-menu-button-label-signs{
  position: fixed !important;
  top: .3% !important;
  right: 12% !important;
}

@media only screen and (max-width: 460px) {
  .p-slidemenu-content {
    width: fit-content;
  }
}