
@media screen and (min-width: 1280px){
  body {
.container {
    position: relative !important;
    height: 888px;
  }
  
.background-image-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 22%;
    height: 888px;
  }

  .background-image-top {
    position: relative;
    top: 0;
    left: 21%;
    width: 160vh;
    height: auto;
    z-index: -1;
  }

  .container-tree-button {
    position: absolute !important;
    /* display: flex !important; */
    height: auto !important;  
  }

  .tree-container {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    width: 100px !important;
    z-index: 2 !important;
  }

.container-card {
  position: relative !important;
  display: flex !important;
  height: 888px !important;
}

.rounded-tree
 {
  border-radius: 20px !important;
  border: none;
  background-color: transparent !important;
  color: #000 !important;
  padding: 20px !important;
}

.tree-admin-button{
  /* padding-left: 50% !important; */
  background-color: aliceblue;
  color: #000;
  left: 10%;
  /* padding: 20px !important; */
}

.card-container {
  position: relative !important;
  left: 30% !important;
  top: 30% !important;
  width: calc(70vw - 544px) !important; ;
  z-index: 2;
  /* text-align: justify; */
  font-size: medium;
}

.card-container-mobile {
  position: relative !important;
  left: 0 !important;
  top: auto !important;
  width: calc(100vw - 774px) !important;;
  z-index: 3 !important;
}
  }
}

@media screen and (min-width: 1000px) and (max-width: 1280px){
  body {
.container {
    position: relative !important;
    height: 888px;
  }
  
.background-image-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 22%;
    height: 888px;
  }

  .background-image-top {
    position: relative;
    top: 0;
    left: 21%;
    width: 160vh;
    height: auto;
    z-index: -1;
  }

  .container-tree-button {
    position: absolute !important;
    /* display: flex !important; */
    height: auto !important;  
  }

  .tree-container {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    width: 100px !important;
    z-index: 2 !important;
  }

.container-card {
  position: relative !important;
  display: flex !important;
  height: 888px !important;
}

.rounded-tree
 {
  border-radius: 20px !important;
  border: none;
  background-color: transparent !important;
  color: #000 !important;
  padding: 20px !important;
}

.tree-admin-button{
  /* padding-left: 50% !important; */
  background-color: aliceblue;
  color: #000;
  left: 10%;
  /* padding: 20px !important; */
}

.card-container {
  position: relative !important;
  left: 30% !important;
  top: 30% !important;
  width: calc(100vw - 588px) !important; ;
  z-index: 2;
  /* text-align: justify; */
  font-size: medium;
}

.card-container-mobile {
  position: relative !important;
  left: 0 !important;
  top: auto !important;
  width: calc(100vw - 774px) !important;;
  z-index: 3 !important;
}
  }
}

ul {
  list-style-type: none;
}
 @media only screen and (max-width: 1000px) {
  body {
    /* .background-image-top{
      display: none;
    }
    .background-image-left{
      display: none;
    }
    .card-container {
      position: relative;
      left: 1%;
      top: auto;
      width: 500px;
      z-index: 2; 
    }*/
    
    .card-container{
      padding: 10px !important;
      text-align: justify !important;
      font-size: large;
      padding-right: 1rem !important;
    }
  }
} 