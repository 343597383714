@media screen and (min-width: 1280px) {
    .player-wrapper {
        zoom: 85%;
        -moz-transform: scale(85%) !important;
    }

    .image-missing-video-or-error {
        width: 40vw;
    }
}

@media screen and (min-width: 1000px) and (max-width:1280px) {
    .player-wrapper {
        zoom: 80% !important;
        -moz-transform: scale(80%) !important;
    }

    .image-missing-video-or-error {
        width: 40vw;
    }

    .progress-bar-custom {
        width: 45vw;
    }
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
    .image-galeria {
        width: calc(75vh + 75vh * .33) !important;
        height: 71vh !important;
    }

    video {
        pointer-events: none !important;
        width: calc(75vh + 75vh * .33) !important;
        height: 75vh !important;
    }
    
    iframe {
        pointer-events: none !important;
        width: calc(75vh + 75vh * .255) !important;
        height: 75vh !important;
        /*   width: 97vw !important;
       height: calc(97vw * .795);*/
    }

    .player-button{
        zoom: 78% !important;
        -moz-transform: scale(78%) !important;
    }
}

@media screen and (max-width: 1000px) {
    .player-wrapper {
        zoom: 97% !important;
        -moz-transform: scale(97%) !important;
    }

    .image-missing-video-or-error {
        width: 99vw;
    }
}

.skeleton-video {
    width: 12rem;
    /* Base width */
    height: 22rem !important;
    /* Base height */
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    /* Shadow with 2px blur */
    border-radius: 0.25rem;
    /* Rounded corners */
}

/* Small screen width */
@media (min-width: 640px) {
    .skeleton-video {
        width: 16rem !important;
        /* Adjusted width for small screens */
    }
}

/*medium */
@media (min-width: 1000px) {
    .skeleton-video {
        margin-left: 0;
        width: 40rem !important;
        height: 28rem !important;
    }
}

/* Extra-large screen width */
@media (min-width: 1280px) {
    .skeleton-video {
        margin-left: 0;
        width: 49rem !important;
        /* Adjusted width for extra-large screens */
        height: 35rem !important;
    }
}

.player-button {
    display: block !important;
    padding-top: .5rem !important;
}

.player {
    /* pointer-events: none !important; */
    display: block !important;
    width: auto !important;
    object-fit: cover !important;
}

.p-progressbar {
    height: 3vh !important;
    font-size: 0% !important;
}